import apirecebimentoweb from '@/services/api-recebimentoweb';

let findTimeout = null;

export default {
  namespaced: true,

  state: {
    list: [],
  },

  getters: {
    list: (state) => state.list,
  },

  // NUNCA ALTERAR state DIRETAMENTE EM ACTIONS
  actions: {
    async login(_, payload) {
      const { data } = await apirecebimentoweb.post('users/login', payload);

      const expires = new Date(data.ttl);
      return { token: data.id, expires, userId: data.userId };
    },

    async logout() {
      await apirecebimentoweb.post('users/logout');
    },

    async find(_, {
      where, ...params
    }) {
      const [{ data }, { data: { count: total } }] = await Promise.all([
        apirecebimentoweb.get('users', { params: { ...where, ...params } }),
        apirecebimentoweb.get('users/count', { params: where }),
      ]);

      return { data, total };
    },

    // CONSULTA COM CONTROLE DE CACHE
    async findWithCache({ commit, state }, { force, ttl } = {}) {
      if (!force && state.list?.length > 0) {
        return;
      }

      clearTimeout(findTimeout);

      const { data } = await apirecebimentoweb.get('users');

      commit('SET_LIST', data);

      if (ttl === Infinity) return;

      findTimeout = setTimeout(() => {
        commit('SET_LIST', null);
      }, ttl ?? 1000 * 60); // TTL -> Time To Live
    },

    // CONSULTA SEM CACHE
    async findById(_, { id }) {
      const { data } = await apirecebimentoweb.get(`users/${id}`);
      return data;
    },

    async findByWebId(_, { id }) {
      const { data } = await apirecebimentoweb.get(`users/webid/${id}`);
      return data;
    },

    async updateById(_, { id }) {
      const { data } = await apirecebimentoweb.put(`users/${id}`);
      return data;
    },

    async newUpdateById(_, payload) {
      const { data } = await apirecebimentoweb.put(`users/change/${payload.newId}`, payload.userJSON);
      return data;
    },

    async loginSenhaForte(_, payload) {
      const data = await apirecebimentoweb.post('users/empresa/login/app', payload);
      return data;
    },

    async validaSenhaForte(_, payload) {
      const res = await apirecebimentoweb.put(`users/comvalidacao/app/${payload.id}`, payload);
      return res;
    },
  },

  // APENAS O MUTATION FAZ ALTERAÇÕES NO STATE
  mutations: {
    SET_LIST(state, list) {
      state.list = list ?? [];
    },
  },
};
