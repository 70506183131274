export const TOKEN = 'auth:token';

export const VISITANTES_STORAGE = {

  statusVisitantes: 'status-visitantes',
};

export const RECEBIMENTO_STORAGE = {
  createFrom: 'recebimento:create-from',
  createBefore: 'recebimento:create-before',
  page: 'recebimento:page',
  sort: 'recebimento:sort',
  search: 'recebimento:search',
  textofiltro: 'recebimento:textofiltro',
  parametro: 'recebimento:parametro',
};

export const RECEBIMENTO_RESUMO_STORAGE = {
  createFrom: 'resumo:create-from',
  createBefore: 'resumo:create-before',
  page: 'resumo:page',
  sort: 'resumo:sort',
  search: 'resumo:search',

};

export const ESTOQUE_STORAGE = {
  createFrom: 'estoque:create-from',
  createBefore: 'estoque:create-before',
  page: 'estoque:page',
  sort: 'estoque:sort',
  search: 'estoque:search',

};

export const LOTES_STORAGE = {
  createFrom: 'lotes:create-from',
  createBefore: 'lotes:create-before',
  prefixo: 'lotes:prefixo',
  page: 'lotes:page',
  sort: 'lotes:sort',
  search: 'lotes:search',
  textofiltro: 'lotes:textofiltro',

};

export const PRODUCAO_STORAGE = {
  createFrom: 'producao:create-from',
  createBefore: 'producao:create-before',
  page: 'producao:page',
  sort: 'producao:sort',
  search: 'producao:search',

};

export const FILA_STORAGE = {
  statusFila: 'status-fila',
  tipoFila: 'tipo-fila',
  interval: 'interval',
};

export const CONSULTA_STORAGE = {
  statusFila: 'status-fila',
  tipoFila: 'tipo-fila',
};

export const CADASTRO_STORAGE = {
  statusFila: 'status-fila',
  tipoFila: 'tipo-fila',
};

export const MODULOS_STORAGE = {
  modulos: 'permitidos',
};

export const USER_STORAGE = {
  userId: 'id-usuario',
  trocasenha: 'troca-senha',
  instrucoes: 'troca-senha-instrucoes',
};

export const A_PAGAR_STORAGE = {
  createFrom: 'apagar:create-from',
  createBefore: 'apagar:create-before',
  parametro: 'apagar:parametro',
  page: 'apagar:page',
  sort: 'apagar:sort',
  search: 'apagar:search',
  textofiltro: 'apagar:textofiltro',
};

export const A_RECEBER_STORAGE = {
  createFrom: 'areceber:create-from',
  createBefore: 'areceber:create-before',
  parametro: 'areceber:parametro',
  page: 'areceber:page',
  sort: 'areceber:sort',
  search: 'areceber:search',
  textofiltro: 'areceber:textofiltro',
};

export const IMG_CONTRATO_STORAGE = {
  numero: 'imgcontrato:numero',
  sufixo: 'imgcontrato:sufixo',
  page: 'imgcontrato:page',
};
